<template>
  <div class="Menu">
    <b-navbar toggleable="lg" type="dark" variant="info" class="container">
        <b-navbar-brand href='/'>
            <b-img class="logo" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/SimPark_white.png"></b-img>
            <!-- <span class="clubText">.club</span> -->
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <template v-if="this.$route.name == 'home'">
          <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                  <b-nav-item class="navItems" href="https://golfpark.club/" target="_blank">Golf Simulator</b-nav-item>
                  <b-nav-item class="navItems"  href="https://flightpark.club/" target="_blank">Flight Simulator</b-nav-item>
                  <b-nav-item class="navItems"  href="https://racepark.club/" target="_blank">Racing Simulators</b-nav-item>
                  <template v-if="isMobile(true)">
                    <b-nav-item class="navItems" :href="'https://apps.apple.com/gb/app/sim-park/id6738054804'" target="_blank">
                      <img class="storeIcons" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/Group-78.png">
                       Download App
                    </b-nav-item>
                    <b-nav-item class="navItems" :href="'https://play.google.com/store/apps/details?id=com.ocssoftware.simpark.app'" target="_blank">
                      <img class="storeIcons" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/Group-79.png">
                      Download App
                    </b-nav-item>
                  </template>
                  <template v-else>
                    <b-nav-item class="navItems appleStoreItem" :href="'https://apps.apple.com/gb/app/sim-park/id6738054804'" target="_blank">
                      <img class="storeIcons" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/Group-78.png">
                    </b-nav-item>
                    <b-nav-item class="navItems goolgeStoreItem" :href="'https://play.google.com/store/apps/details?id=com.ocssoftware.simpark.app'" target="_blank">
                      <img class="storeIcons" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/Group-79.png">
                    </b-nav-item>
                  </template>
              </b-navbar-nav>
          </b-collapse>
        </template>
        <template v-else>
          <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                  <b-nav-item class="navItems" href="https://golfpark.club/" target="_blank">Golf Simulator</b-nav-item>
                  <b-nav-item class="navItems" href="https://flightpark.club/" target="_blank">Flight Simulator</b-nav-item>
                  <b-nav-item class="navItems" href="https://racepark.club/" target="_blank">Racing Simulators</b-nav-item>
                  <template v-if="isMobile(true)">
                    <b-nav-item class="navItems" :href="'https://apps.apple.com/gb/app/sim-park/id6738054804'" target="_blank">
                      <img class="storeIcons" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/Group-78.png">
                       Download App
                    </b-nav-item>
                    <b-nav-item class="navItems" :href="'https://play.google.com/store/apps/details?id=com.ocssoftware.simpark.app'" target="_blank">
                      <img class="storeIcons" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/Group-79.png">
                      Download App
                    </b-nav-item>
                  </template>
                  <template v-else>
                    <b-nav-item class="navItems appleStoreItem" :href="'https://apps.apple.com/gb/app/sim-park/id6738054804'" target="_blank">
                      <img class="storeIcons" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/Group-78.png">
                    </b-nav-item>
                    <b-nav-item class="navItems goolgeStoreItem" :href="'https://play.google.com/store/apps/details?id=com.ocssoftware.simpark.app'" target="_blank">
                      <img class="storeIcons" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/Group-79.png">
                    </b-nav-item>
                  </template>
              </b-navbar-nav>
          </b-collapse>
        </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {

  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
    scroll() {
      var score = document.getElementById("about");
      score.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    scrolltwo() {
      var score = document.getElementById("membership");
      score.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    // scrollthree() {
    //   var score = document.getElementById("contact");
    //   score.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //     inline: "nearest",
    //   });
    // },
    // scrollfour() {
    //   var score = document.getElementById("blog");
    //   score.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //     inline: "nearest",
    //   });
    // },
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    li.nav-item.navItems.appleStoreItem {
      padding-right: 0px;
    }
    li.nav-item.navItems.goolgeStoreItem {
      padding-left: 0px;
    }
    img.storeIcons {
      width: 42px;
    }
    .clubText {
      color: #FF7600;
      font-style: italic;
      font-size: 29px;
      font-weight: bold;
      position: relative;
      top: 15px;
      left: -11px;
    }
    .Menu {
      /* display: none; */
    }
    .hide {
      opacity:0;
      display: none;
    }
    .show {
      opacity: 1;
      background: #000;
      /* position: fixed; */
      width: 100%;
      z-index: 999;
    }
    img.supportLogos {
      width: 45px;
    }
    ::v-deep .btn-danger {
      color: #fff;
      background-color: #efd170;
      border-color: #efd170!important;
    }
    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
        color: #DD112B;
    }
    .navItems {
        /* padding-right: 40px;
        padding-left: 40px; */
        padding-right: 21px;
        padding-left: 21px;
        line-height: 3;
        scroll-behavior: smooth;
        color:#fff;
    }
    .navItems:hover {
        /* padding-right: 30px;
        padding-left: 30px; */
        line-height: 3;
        scroll-behavior: smooth;
        color:rgb(232 63 75);
        text-decoration: none;
    }
    .logo {
        width: 190px;
    }
    .Menu {
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: #000;
      /* position: absolute;
      top: 0;
      z-index: 999;
      width: 100%; */
    }
    .bg-info {
      background-color: transparent !important;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: #ffffff;
        font-size: 20px;
        font-family: "noto-sans", sans-serif;
        font-weight: 600;
        font-style: normal;
    }
    button.getInTouch {
        background-color: #d80e1f;
        border-color: #d80e1f!important;
        border-radius: 50px;
        width: 163px;
        height: 31px;
        line-height: 1;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        width: 259px;
        height: 54px;
    }
    button.getInTouch:hover {
        background-color: #707070;
        border-color: #707070!important;
        color: #fff;
    }

    @media only screen and (max-width: 500px) {
      img.storeIcons {
          width: 35px;
          padding-right: 5px;
          margin-right: 5px;
      }
      .logo {
          width: 150px;
      }
      .Menu{
          padding-top: 5px;
          padding-bottom: 5px;
      }
      img.supportLogos {
          width: 50px;
          margin-top: 15px;
      }
    }
</style>
