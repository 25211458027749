<template>
    <div class="outerHomepage">
        <div class="HomeTopImage">
            <div class="homepage">
                <div class="HomeMain-Text">
                    <template v-if="isMobile(true)">
                        <h2 class="HomeTitle">Indoor Flight, Racing<br> and Golf Simulator <br> studio in Sussex</h2>
                    </template>
                    <template v-else>
                        <h2 class="HomeTitle">Indoor Flight, Racing and <br>Golf Simulator studio in Sussex</h2>
                    </template>
                    <template v-if="isMobile(true)">
                        <h3 class="HomeSubTitle">The ultimate indoor simulator experience in Uckfield, Sussex Our facility combines the latest in technology with unmatched entertainment to bring you a world-class venue for enthusiasts of aviation, motorsports and golf </h3>
                    </template>
                    <template v-else>
                        <h3 class="HomeSubTitle">The ultimate indoor simulator experience in Uckfield, Sussex Our facility combines the latest<br> in technology with unmatched entertainment to bring you a world-class venue <br> for enthusiasts of aviation, motorsports and golf </h3>
                    </template>
                    <b-link :to="'/contact'">
                        <b-button class="HomepageBtn">Enquire Today</b-button>
                    </b-link>
                    <div class="homePageArrow">
                        <img class="arrowHomepage pulse" @click="scrollNextSection()" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/White-page-down-arrow.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HomeTop Image',
  components: {

  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 768 ) {
            return true;
        }
        else {
            return false;
        }
    },
    scrollNextSection() {
      var score = document.getElementById("about");
      score.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
  mounted() {
    axios //add API Call
        .get(
            process.env.VUE_APP_API_URL + 'simpark' + "/wp-json/wp/v2/pages/25" + '?randomadd=' +
          new Date().getTime()
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
h3.HomeSubTitle {
    color: #ffffff;
    font-family: "noto-sans", sans-serif;
    font-weight: 500;
    font-style: normal;
}
span.trackManText {
    color: #fff;
    font-weight: bold;
}
.HomeMain-Text {
    /* padding-top: 100px; */
    /* padding-top: 350px; */
    padding-top: 250px;
}
.HomeTitle {
    color: #fff;
    font-family: "korolev", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 80px;
    padding-bottom: 60px;
}
.HomeTopImage {
    background-image: url('https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/F1-Background.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 1080px;
    height: 1080px;
    position: relative;
    background-attachment: fixed;
    /* height: 800px;
    max-height: 800px; */
}
button.HomepageBtn {
    background-color: #d80e1f;
    border-color: #d80e1f!important;
    border-radius: 50px;
    width: 163px;
    height: 31px;
    line-height: 1;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 252px;
    height: 54px;
    margin-top: 65px;
    font-family: "noto-sans", sans-serif;
}
button.HomepageBtn:hover {
    background-color: #707070;
    border-color: #707070!important;
    color: #fff;
}
.arrowHomepage {
    height: 40px;
    width: 70px;
    cursor: pointer;
}
.homePageArrow {
    margin-top: 100px;
}
.pulse {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.btn-circle {
  width: 90px;
  height: 90px;
  border-radius: 60px;
  font-size: 40px;
  color: rgba(255, 255, 255, 0.6);
  -webkit-transition: background .4s ease-in-out;
  -moz-transition: background .4s ease-in-out;
  transition: background .4s ease-in-out;
}

.btn-circle:hover, .btn-circle:focus {
  outline: 0;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

/* Mobile Changes */
@media only screen and (max-width: 768px) {
    .HomeTitle {
        color: #fff;
        font-weight: bold;
        font-style: italic;
        font-size: 30px;
        padding-bottom: 24px;
        padding-left: 30px;
        padding-right: 30px;
    }
    h3, .h3{
        font-size: 18px;
        padding-left: 32px;
        padding-right: 32px;
    }
    h3.HomeSubTitle {
        line-height: 1.5;
    }
    .HomeMain-Text{
        padding-top: 120px;
        /* padding-top: 250px; */
    }
    .HomeTopImage {
        max-height: 900px;
        height: 750px;
        background-position: center;
        background-attachment: initial!important;
    }
    button.HomepageBtn {
        margin-top: 47px;
    }
    .homePageArrow {
        margin-top: 65px;
    }
}
@media only screen and (max-width: 375px) {
    .HomeTitle {
        color: #fff;
        font-weight: bold;
        font-style: italic;
        font-size: 35px;
        padding-bottom: 60px;
    }
    h3, .h3{
        font-size: 20px;
        padding-left: 32px;
        padding-right: 32px;
    }
    .HomeTopImage {
        max-height: 900px;
        height: 900px;
        background-position: center;
        background-attachment: initial !important;
    }
}

</style>